import { MutableRefObject } from "react";
import {
  composeRenderProps,
  Button as RACButton,
  ButtonProps as RACButtonProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";
import { focusRing } from "./utils";

export interface ButtonProps extends RACButtonProps {
  variant?:
    | "primary"
    | "secondary"
    | "whity"
    | "destructive"
    | "icon"
    | "default"
    | "text";
  padding?: "none" | "xs" | "small" | "medium";
  ref?: MutableRefObject<null>;
}

let button = tv({
  extend: focusRing,
  base: "group flex items-center justify-center gap-x-2 shadow-sm",
  variants: {
    variant: {
      primary:
        "border-0 text-white bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 text-base leading-[normal] font-medium rounded",
      secondary:
        "text-blue-500 bg-blue-25 hover:bg-blue-100 focus:bg-blue-100 text-base leading-[normal] font-medium rounded border border-blue-200 hover:border-blue-500",
      whity:
        "text-primary-800 bg-white hover:bg-blue-25 focus:bg-blue-25 text-base leading-[normal] rounded border border-primary-50 hover:border-blue-100 focus:border-blue-100",
      text: "text-secondary-main-100 hover:text-primary-main-500 focus:text-primary-main-500 text-base leading-[normal] font-medium",
      default:
        "text-secondary-main-100 bg-secondary-main-10 hover:bg-secondary-main-20 focus:bg-secondary-main-20 text-base leading-[normal] font-medium rounded",
      destructive:
        "bg-red-500 hover:bg-red-600 focus:bg-red-600 text-white text-base leading-[normal] font-medium rounded",
      icon: "border-0 p-1 flex items-center justify-center text-secondary-main-100 hover:bg-primary-main-50 hover:text-primary-main-500 pressed:bg-primary-main-500 pressed:text-white disabled:bg-transparent",
    },
    padding: {
      none: "p-0",
      xs: "p-1",
      small: "px-3 py-2",
      medium: "px-6 py-3",
    },
    isDisabled: {
      true: "bg-secondary-main-10 text-secondary-main-100 border-secondary-main-20",
    },
  },
  defaultVariants: {
    variant: "primary",
    padding: "medium",
  },
  compoundVariants: [
    {
      isDisabled: true,
      variant: ["primary", "secondary"],
      class:
        "hover:bg-secondary-main-10 hover:text-secondary-main-100 hover:border-secondary-main-20",
    },
  ],
});

/**
 * React-aria-components Button implementation with application styles (based on https://react-spectrum.adobe.com/react-aria-tailwind-starter/index.html?path=/docs/button--docs)
 * @param props containing
 * variant: tailwind variant to set wanted styles on component (possible values: "primary"
    | "secondary"
    | "whity"
    | "destructive"
    | "icon"
    | "default"
    | "text")
 * padding: tailwind variant to set padding on component (possible values: "none" | "xs" | "small" | "medium")
 */
export default function Button(props: ButtonProps) {
  return (
    <RACButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        button({
          ...renderProps,
          variant: props.variant,
          padding: props.padding,
          className,
        })
      )}
    />
  );
}
